import React, { Component } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Table, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import axios from 'axios';

import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';
import PageLoader from '../components/PageLoader';
import ButtonLoading from '../components/ButtonLoading';

import params from '../utils/params';
import localStorage from '../utils/localStorage';
import { toastHtmlStatus } from '../utils/sweetAlert';

export default class Status extends Component {
  constructor() {
    super();

    let user = JSON.parse(localStorage.getUser());
    if (!user) {
      this.state = { invalid: true };
      return;
    }

    this.state = {
      isLoading: true,
      isEditing: false,
      role: user.role,

      // list
      list: [],
      prevList: [],
      staffList: []
    };
  }

  componentDidMount() {
    // get table
    let fetchData1 = axios.get('/roomcode/status').then((success) => {
      this.setState({
        list: success.data,
        prevList: success.data
      });
    });

    // get dropdown
    let fetchData2 = axios.get('/staff').then((success) => {
      this.setState({
        staffList: success.data
      });
    });

    Promise.all([fetchData1, fetchData2]).then((success) => {
      this.setState({ isLoading: false });
    });
  }

  getRoomStatus() {
    axios
      .get('/roomcode/status')
      .then((success) => {
        this.setState({
          list: success.data,
          prevList: success.data
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  handleOnStaffList = (id) => {
    let dropdownOptions = `<option value="">-</option>`;

    this.state.staffList.map((staff) => {
      dropdownOptions =
        dropdownOptions +
        `<option value="${staff.id}" ${staff.id === id ? 'selected' : ''}>${staff.firstname + ' (' + staff.nickname + ')'}</option>`;

      return 0;
    });

    return dropdownOptions;
  };

  handleOnUpdate = (e) => {
    let roomCodeId = e.target.id;

    this.setState({ isLoading: true });

    axios.get('/roomcode/status/' + roomCodeId).then((success) => {
      let item = success.data;
      this.setState({ isLoading: false });

      toastHtmlStatus
        .fire({
          title: `${item.project_id}_${item.room_code} | Job ID: ${'#' + item.job_id ? item.job_id : '-'}`,
          html: `
          <br/>
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Title</th>
                <th width="15%">Status</th>
                <th width="20%">Assigned To</th>
                <th width="5%">Start Date</th>
                <th width="5%">Target Date</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              <tr style="${
                this.state.role === params.ROLE.PRODUCTION_MANAGER || this.state.role === params.ROLE.PURCHASE_MANAGER ? 'display:none' : ''
              }">
                <td colspan="3">Overall</td>
                <td><input type="date" id="startDate" class="form-control" value="${
                  item.status.start_date
                    ? new Date(item.status.start_date).toLocaleDateString('en-US', {
                        year: 'numeric'
                      }) +
                      '-' +
                      new Date(item.status.start_date).toLocaleDateString('en-US', {
                        month: '2-digit'
                      }) +
                      '-' +
                      new Date(item.status.start_date).toLocaleDateString('en-US', {
                        day: '2-digit'
                      })
                    : ''
                }"></td>
                <td><input type="date" id="deadlineDate" class="form-control" value="${
                  item.status.deadline_date
                    ? new Date(item.status.deadline_date).toLocaleDateString('en-US', {
                        year: 'numeric'
                      }) +
                      '-' +
                      new Date(item.status.deadline_date).toLocaleDateString('en-US', {
                        month: '2-digit'
                      }) +
                      '-' +
                      new Date(item.status.deadline_date).toLocaleDateString('en-US', {
                        day: '2-digit'
                      })
                    : ''
                }"></td>
                <td><input type="text" id="note" class="form-control" value="${item.status.note ? item.status.note : ''}"</td>
              </tr>
              <tr style="${this.state.role === params.ROLE.PRODUCTION_MANAGER ? 'display:none' : ''}">
                <td>จัดซื้อ</td>
                <td><select id="purchaseStatus" class="form-control">
                  <option value="">-</option>
                  <option value="${params.JOBSTATUS.NEW}" ${item.status.purchase_status === params.JOBSTATUS.NEW ? 'selected' : ''}>${
            params.JOBSTATUS.NEW
          }</option>
                  <option value="${params.JOBSTATUS.DOING}" ${item.status.purchase_status === params.JOBSTATUS.DOING ? 'selected' : ''}>${
            params.JOBSTATUS.DOING
          }</option>
                  <option value="${params.JOBSTATUS.WAITING}" ${
            item.status.purchase_status === params.JOBSTATUS.WAITING ? 'selected' : ''
          }>${params.JOBSTATUS.WAITING}</option>
                  <option value="${params.JOBSTATUS.COMPLETED}" ${
            item.status.purchase_status === params.JOBSTATUS.COMPLETED ? 'selected' : ''
          }>${params.JOBSTATUS.COMPLETED}</option>
                  <option value="${params.JOBSTATUS.PLANNED}" ${
            item.status.purchase_status === params.JOBSTATUS.PLANNED ? 'selected' : ''
          }>${params.JOBSTATUS.PLANNED}</option>
                  <option value="${params.JOBSTATUS.RESERVED}" ${
            item.status.purchase_status === params.JOBSTATUS.RESERVED ? 'selected' : ''
          }>${params.JOBSTATUS.RESERVED}</option>
                </select></td>
                <td><select id="purchaseAssignee" class="form-control">${this.handleOnStaffList(
                  item.status.purchase_assignee
                )}</select></td>
                <td>
                  <input type="date" id="purchaseDateStart" class="form-control" value="${
                    item.status.purchase_date_start
                      ? new Date(item.status.purchase_date_start).toLocaleDateString('en-US', {
                          year: 'numeric'
                        }) +
                        '-' +
                        new Date(item.status.purchase_date_start).toLocaleDateString('en-US', {
                          month: '2-digit'
                        }) +
                        '-' +
                        new Date(item.status.purchase_date_start).toLocaleDateString('en-US', {
                          day: '2-digit'
                        })
                      : ''
                  }">
                  </td>
                <td>
                  <input type="date" id="purchaseDateTarget" class="form-control" value="${
                    item.status.purchase_date_target
                      ? new Date(item.status.purchase_date_target).toLocaleDateString('en-US', {
                          year: 'numeric'
                        }) +
                        '-' +
                        new Date(item.status.purchase_date_target).toLocaleDateString('en-US', {
                          month: '2-digit'
                        }) +
                        '-' +
                        new Date(item.status.purchase_date_target).toLocaleDateString('en-US', {
                          day: '2-digit'
                        })
                      : ''
                  }">
                  </td>
                  <td><input type="text" id="purchaseNote" class="form-control" value="${
                    item.status.purchase_note ? item.status.purchase_note : ''
                  }"</td>
              </tr>
              <tr style="${this.state.role === params.ROLE.PURCHASE_MANAGER ? 'display:none' : ''}">
                <td>ผลิต</td>
                <td><select id="machineStatus" class="form-control">
                  <option value="">-</option>
                  <option value="${params.JOBSTATUS.NEW}" ${item.status.machine_status === params.JOBSTATUS.NEW ? 'selected' : ''}>${
            params.JOBSTATUS.NEW
          }</option>
                  <option value="${params.JOBSTATUS.DOING}" ${item.status.machine_status === params.JOBSTATUS.DOING ? 'selected' : ''}>${
            params.JOBSTATUS.DOING
          }</option>
                  <option value="${params.JOBSTATUS.WAITING}" ${
            item.status.machine_status === params.JOBSTATUS.WAITING ? 'selected' : ''
          }>${params.JOBSTATUS.WAITING}</option>
                  <option value="${params.JOBSTATUS.COMPLETED}" ${
            item.status.machine_status === params.JOBSTATUS.COMPLETED ? 'selected' : ''
          }>${params.JOBSTATUS.COMPLETED}</option>
                  <option value="${params.JOBSTATUS.PLANNED}" ${
            item.status.machine_status === params.JOBSTATUS.PLANNED ? 'selected' : ''
          }>${params.JOBSTATUS.PLANNED}</option>
                  <option value="${params.JOBSTATUS.RESERVED}" ${
            item.status.machine_status === params.JOBSTATUS.RESERVED ? 'selected' : ''
          }>${params.JOBSTATUS.RESERVED}</option>
                </select></td>
                <td><select id="machineAssignee" class="form-control">${this.handleOnStaffList(item.status.machine_assignee)}</select></td>
                <td>
                  <input type="date" id="machineDateStart" class="form-control" value="${
                    item.status.machine_date_start
                      ? new Date(item.status.machine_date_start).toLocaleDateString('en-US', {
                          year: 'numeric'
                        }) +
                        '-' +
                        new Date(item.status.machine_date_start).toLocaleDateString('en-US', {
                          month: '2-digit'
                        }) +
                        '-' +
                        new Date(item.status.machine_date_start).toLocaleDateString('en-US', {
                          day: '2-digit'
                        })
                      : ''
                  }">
                  </td>
                <td>
                  <input type="date" id="machineDateTarget" class="form-control" value="${
                    item.status.machine_date_target
                      ? new Date(item.status.machine_date_target).toLocaleDateString('en-US', {
                          year: 'numeric'
                        }) +
                        '-' +
                        new Date(item.status.machine_date_target).toLocaleDateString('en-US', {
                          month: '2-digit'
                        }) +
                        '-' +
                        new Date(item.status.machine_date_target).toLocaleDateString('en-US', {
                          day: '2-digit'
                        })
                      : ''
                  }">
                  </td>
                  <td><input type="text" id="machineNote" class="form-control" value="${
                    item.status.machine_note ? item.status.machine_note : ''
                  }"</td>
              </tr>
              <tr style="${this.state.role === params.ROLE.PURCHASE_MANAGER ? 'display:none' : ''}">
                <td>ประกอบ</td>
                <td><select id="assemblyStatus" class="form-control">
                  <option value="">-</option>
                  <option value="${params.JOBSTATUS.NEW}" ${item.status.assembly_status === params.JOBSTATUS.NEW ? 'selected' : ''}>${
            params.JOBSTATUS.NEW
          }</option>
                  <option value="${params.JOBSTATUS.DOING}" ${item.status.assembly_status === params.JOBSTATUS.DOING ? 'selected' : ''}>${
            params.JOBSTATUS.DOING
          }</option>
                  <option value="${params.JOBSTATUS.WAITING}" ${
            item.status.assembly_status === params.JOBSTATUS.WAITING ? 'selected' : ''
          }>${params.JOBSTATUS.WAITING}</option>
                  <option value="${params.JOBSTATUS.COMPLETED}" ${
            item.status.assembly_status === params.JOBSTATUS.COMPLETED ? 'selected' : ''
          }>${params.JOBSTATUS.COMPLETED}</option>
                  <option value="${params.JOBSTATUS.PLANNED}" ${
            item.status.assembly_status === params.JOBSTATUS.PLANNED ? 'selected' : ''
          }>${params.JOBSTATUS.PLANNED}</option>
                  <option value="${params.JOBSTATUS.RESERVED}" ${
            item.status.assembly_status === params.JOBSTATUS.RESERVED ? 'selected' : ''
          }>${params.JOBSTATUS.RESERVED}</option>
                </select></td>
                <td><select id="assemblyAssignee" class="form-control">${this.handleOnStaffList(
                  item.status.assembly_assignee
                )}</select></td>
                <td>
                  <input type="date" id="assemblyDateStart" class="form-control" value="${
                    item.status.assembly_date_start
                      ? new Date(item.status.assembly_date_start).toLocaleDateString('en-US', {
                          year: 'numeric'
                        }) +
                        '-' +
                        new Date(item.status.assembly_date_start).toLocaleDateString('en-US', {
                          month: '2-digit'
                        }) +
                        '-' +
                        new Date(item.status.assembly_date_start).toLocaleDateString('en-US', {
                          day: '2-digit'
                        })
                      : ''
                  }">
                  </td>
                  <td>
                  <input type="date" id="assemblyDateTarget" class="form-control" value="${
                    item.status.assembly_date_target
                      ? new Date(item.status.assembly_date_target).toLocaleDateString('en-US', {
                          year: 'numeric'
                        }) +
                        '-' +
                        new Date(item.status.assembly_date_target).toLocaleDateString('en-US', {
                          month: '2-digit'
                        }) +
                        '-' +
                        new Date(item.status.assembly_date_target).toLocaleDateString('en-US', {
                          day: '2-digit'
                        })
                      : ''
                  }">
                  </td>
                  <td><input type="text" id="assemblyNote" class="form-control" value="${
                    item.status.assembly_note ? item.status.assembly_note : ''
                  }"</td>
              </tr>
              <tr style="${this.state.role === params.ROLE.PURCHASE_MANAGER ? 'display:none' : ''}">
                <td>ตั้งตู้</td>
                <td><select id="mockupStatus" class="form-control">
                  <option value="">-</option>
                  <option value="${params.JOBSTATUS.NEW}" ${item.status.mockup_status === params.JOBSTATUS.NEW ? 'selected' : ''}>${
            params.JOBSTATUS.NEW
          }</option>
                  <option value="${params.JOBSTATUS.DOING}" ${item.status.mockup_status === params.JOBSTATUS.DOING ? 'selected' : ''}>${
            params.JOBSTATUS.DOING
          }</option>
                  <option value="${params.JOBSTATUS.WAITING}" ${item.status.mockup_status === params.JOBSTATUS.WAITING ? 'selected' : ''}>${
            params.JOBSTATUS.WAITING
          }</option>
                  <option value="${params.JOBSTATUS.COMPLETED}" ${
            item.status.mockup_status === params.JOBSTATUS.COMPLETED ? 'selected' : ''
          }>${params.JOBSTATUS.COMPLETED}</option>
                  <option value="${params.JOBSTATUS.PLANNED}" ${item.status.mockup_status === params.JOBSTATUS.PLANNED ? 'selected' : ''}>${
            params.JOBSTATUS.PLANNED
          }</option>
                  <option value="${params.JOBSTATUS.RESERVED}" ${
            item.status.mockup_status === params.JOBSTATUS.RESERVED ? 'selected' : ''
          }>${params.JOBSTATUS.RESERVED}</option>
                </select></td>
                <td><select id="mockupAssignee" class="form-control">${this.handleOnStaffList(item.status.mockup_assignee)}</select></td>
                <td>
                  <input type="date" id="mockupDateStart" class="form-control" value="${
                    item.status.mockup_date_start
                      ? new Date(item.status.mockup_date_start).toLocaleDateString('en-US', {
                          year: 'numeric'
                        }) +
                        '-' +
                        new Date(item.status.mockup_date_start).toLocaleDateString('en-US', {
                          month: '2-digit'
                        }) +
                        '-' +
                        new Date(item.status.mockup_date_start).toLocaleDateString('en-US', {
                          day: '2-digit'
                        })
                      : ''
                  }">
                  </td>
                  <td>
                  <input type="date" id="mockupDateTarget" class="form-control" value="${
                    item.status.mockup_date_target
                      ? new Date(item.status.mockup_date_target).toLocaleDateString('en-US', {
                          year: 'numeric'
                        }) +
                        '-' +
                        new Date(item.status.mockup_date_target).toLocaleDateString('en-US', {
                          month: '2-digit'
                        }) +
                        '-' +
                        new Date(item.status.mockup_date_target).toLocaleDateString('en-US', {
                          day: '2-digit'
                        })
                      : ''
                  }">
                  </td>
                  <td><input type="text" id="mockupNote" class="form-control" value="${
                    item.status.mockup_note ? item.status.mockup_note : ''
                  }"</td>
              </tr>
              <tr style="${this.state.role === params.ROLE.PURCHASE_MANAGER ? 'display:none' : ''}">
                <td>ทำสี</td>
                <td><select id="paintStatus" class="form-control">
                  <option value="">-</option>
                  <option value="${params.JOBSTATUS.NEW}" ${item.status.paint_status === params.JOBSTATUS.NEW ? 'selected' : ''}>${
            params.JOBSTATUS.NEW
          }</option>
                  <option value="${params.JOBSTATUS.DOING}" ${item.status.paint_status === params.JOBSTATUS.DOING ? 'selected' : ''}>${
            params.JOBSTATUS.DOING
          }</option>
                  <option value="${params.JOBSTATUS.WAITING}" ${item.status.paint_status === params.JOBSTATUS.WAITING ? 'selected' : ''}>${
            params.JOBSTATUS.WAITING
          }</option>
                  <option value="${params.JOBSTATUS.COMPLETED}" ${
            item.status.paint_status === params.JOBSTATUS.COMPLETED ? 'selected' : ''
          }>${params.JOBSTATUS.COMPLETED}</option>
                  <option value="${params.JOBSTATUS.PLANNED}" ${item.status.paint_status === params.JOBSTATUS.PLANNED ? 'selected' : ''}>${
            params.JOBSTATUS.PLANNED
          }</option>
                  <option value="${params.JOBSTATUS.RESERVED}" ${
            item.status.paint_status === params.JOBSTATUS.RESERVED ? 'selected' : ''
          }>${params.JOBSTATUS.RESERVED}</option>
                </select></td>
                <td><select id="paintAssignee" class="form-control">${this.handleOnStaffList(item.status.paint_assignee)}</select></td>
                <td>
                  <input type="date" id="paintDateStart" class="form-control" value="${
                    item.status.paint_date_start
                      ? new Date(item.status.paint_date_start).toLocaleDateString('en-US', {
                          year: 'numeric'
                        }) +
                        '-' +
                        new Date(item.status.paint_date_start).toLocaleDateString('en-US', {
                          month: '2-digit'
                        }) +
                        '-' +
                        new Date(item.status.paint_date_start).toLocaleDateString('en-US', {
                          day: '2-digit'
                        })
                      : ''
                  }">
                  </td>
                  <td>
                  <input type="date" id="paintDateTarget" class="form-control" value="${
                    item.status.paint_date_target
                      ? new Date(item.status.paint_date_target).toLocaleDateString('en-US', {
                          year: 'numeric'
                        }) +
                        '-' +
                        new Date(item.status.paint_date_target).toLocaleDateString('en-US', {
                          month: '2-digit'
                        }) +
                        '-' +
                        new Date(item.status.paint_date_target).toLocaleDateString('en-US', {
                          day: '2-digit'
                        })
                      : ''
                  }">
                  </td>
                  <td><input type="text" id="paintNote" class="form-control" value="${
                    item.status.paint_note ? item.status.paint_note : ''
                  }"</td>
              </tr>
              <tr style="${this.state.role === params.ROLE.PURCHASE_MANAGER ? 'display:none' : ''}">
                <td>ขนส่ง</td>
                <td><select id="shipStatus" class="form-control">
                  <option value="">-</option>
                  <option value="${params.JOBSTATUS.NEW}" ${item.status.ship_status === params.JOBSTATUS.NEW ? 'selected' : ''}>${
            params.JOBSTATUS.NEW
          }</option>
                  <option value="${params.JOBSTATUS.DOING}" ${item.status.ship_status === params.JOBSTATUS.DOING ? 'selected' : ''}>${
            params.JOBSTATUS.DOING
          }</option>
                  <option value="${params.JOBSTATUS.WAITING}" ${item.status.ship_status === params.JOBSTATUS.WAITING ? 'selected' : ''}>${
            params.JOBSTATUS.WAITING
          }</option>
                  <option value="${params.JOBSTATUS.COMPLETED}" ${
            item.status.ship_status === params.JOBSTATUS.COMPLETED ? 'selected' : ''
          }>${params.JOBSTATUS.COMPLETED}</option>
                  <option value="${params.JOBSTATUS.PLANNED}" ${item.status.ship_status === params.JOBSTATUS.PLANNED ? 'selected' : ''}>${
            params.JOBSTATUS.PLANNED
          }</option>
                  <option value="${params.JOBSTATUS.RESERVED}" ${item.status.ship_status === params.JOBSTATUS.RESERVED ? 'selected' : ''}>${
            params.JOBSTATUS.RESERVED
          }</option>
              </select></td>
              <td><select id="shipAssignee" class="form-control">${this.handleOnStaffList(item.status.ship_assignee)}</select></td>
              <td>
                <input type="date" id="shipDateStart" class="form-control" value="${
                  item.status.ship_date_start
                    ? new Date(item.status.ship_date_start).toLocaleDateString('en-US', {
                        year: 'numeric'
                      }) +
                      '-' +
                      new Date(item.status.ship_date_start).toLocaleDateString('en-US', {
                        month: '2-digit'
                      }) +
                      '-' +
                      new Date(item.status.ship_date_start).toLocaleDateString('en-US', {
                        day: '2-digit'
                      })
                    : ''
                }">
                </td>
                <td>
                <input type="date" id="shipDateTarget" class="form-control" value="${
                  item.status.ship_date_target
                    ? new Date(item.status.ship_date_target).toLocaleDateString('en-US', {
                        year: 'numeric'
                      }) +
                      '-' +
                      new Date(item.status.ship_date_target).toLocaleDateString('en-US', {
                        month: '2-digit'
                      }) +
                      '-' +
                      new Date(item.status.ship_date_target).toLocaleDateString('en-US', {
                        day: '2-digit'
                      })
                    : ''
                }">
                </td>
                <td><input type="text" id="shipNote" class="form-control" value="${item.status.ship_note ? item.status.ship_note : ''}"</td>
              </tr>
              <tr style="${this.state.role === params.ROLE.PURCHASE_MANAGER ? 'display:none' : ''}">
                <td>ติดตั้ง</td>
                <td><select id="installStatus" class="form-control">
                  <option value="">-</option>
                  <option value="${params.JOBSTATUS.NEW}" ${item.status.install_status === params.JOBSTATUS.NEW ? 'selected' : ''}>${
            params.JOBSTATUS.NEW
          }</option>
                  <option value="${params.JOBSTATUS.DOING}" ${item.status.install_status === params.JOBSTATUS.DOING ? 'selected' : ''}>${
            params.JOBSTATUS.DOING
          }</option>
                  <option value="${params.JOBSTATUS.WAITING}" ${
            item.status.install_status === params.JOBSTATUS.WAITING ? 'selected' : ''
          }>${params.JOBSTATUS.WAITING}</option>
                  <option value="${params.JOBSTATUS.COMPLETED}" ${
            item.status.install_status === params.JOBSTATUS.COMPLETED ? 'selected' : ''
          }>${params.JOBSTATUS.COMPLETED}</option>
                  <option value="${params.JOBSTATUS.PLANNED}" ${
            item.status.install_status === params.JOBSTATUS.PLANNED ? 'selected' : ''
          }>${params.JOBSTATUS.PLANNED}</option>
                  <option value="${params.JOBSTATUS.RESERVED}" ${
            item.status.install_status === params.JOBSTATUS.RESERVED ? 'selected' : ''
          }>${params.JOBSTATUS.RESERVED}</option>
                </select></td>
                <td><select id="installAssignee" class="form-control">${this.handleOnStaffList(item.status.install_assignee)}</select></td>
                <td>
                  <input type="date" id="installDateStart" class="form-control" value="${
                    item.status.install_date_start
                      ? new Date(item.status.install_date_start).toLocaleDateString('en-US', {
                          year: 'numeric'
                        }) +
                        '-' +
                        new Date(item.status.install_date_start).toLocaleDateString('en-US', {
                          month: '2-digit'
                        }) +
                        '-' +
                        new Date(item.status.install_date_start).toLocaleDateString('en-US', {
                          day: '2-digit'
                        })
                      : ''
                  }">
                </td>
                <td>
                  <input type="date" id="installDateTarget" class="form-control" value="${
                    item.status.install_date_target
                      ? new Date(item.status.install_date_target).toLocaleDateString('en-US', {
                          year: 'numeric'
                        }) +
                        '-' +
                        new Date(item.status.install_date_target).toLocaleDateString('en-US', {
                          month: '2-digit'
                        }) +
                        '-' +
                        new Date(item.status.install_date_target).toLocaleDateString('en-US', {
                          day: '2-digit'
                        })
                      : ''
                  }">
                </td>
                <td><input type="text" id="installNote" class="form-control" value="${
                  item.status.install_note ? item.status.install_note : ''
                }"</td>
              </tr>
              <tr style="${this.state.role === params.ROLE.PURCHASE_MANAGER ? 'display:none' : ''}">
                <td>ส่งมอบ</td>
                <td><select id="deliveryStatus" class="form-control">
                  <option value="">-</option>
                  <option value="${params.JOBSTATUS.NEW}" ${item.status.delivery_status === params.JOBSTATUS.NEW ? 'selected' : ''}>${
            params.JOBSTATUS.NEW
          }</option>
                  <option value="${params.JOBSTATUS.DOING}" ${item.status.delivery_status === params.JOBSTATUS.DOING ? 'selected' : ''}>${
            params.JOBSTATUS.DOING
          }</option>
                  <option value="${params.JOBSTATUS.WAITING}" ${
            item.status.delivery_status === params.JOBSTATUS.WAITING ? 'selected' : ''
          }>${params.JOBSTATUS.WAITING}</option>
                  <option value="${params.JOBSTATUS.COMPLETED}" ${
            item.status.delivery_status === params.JOBSTATUS.COMPLETED ? 'selected' : ''
          }>${params.JOBSTATUS.COMPLETED}</option>
                  <option value="${params.JOBSTATUS.PLANNED}" ${
            item.status.delivery_status === params.JOBSTATUS.PLANNED ? 'selected' : ''
          }>${params.JOBSTATUS.PLANNED}</option>
                  <option value="${params.JOBSTATUS.RESERVED}" ${
            item.status.delivery_status === params.JOBSTATUS.RESERVED ? 'selected' : ''
          }>${params.JOBSTATUS.RESERVED}</option>
                </select></td>
                <td><select id="deliveryAssignee" class="form-control">${this.handleOnStaffList(
                  item.status.delivery_assignee
                )}</select></td>
                <td>
                  <input type="date" id="deliveryDateStart" class="form-control" value="${
                    item.status.delivery_date_start
                      ? new Date(item.status.delivery_date_start).toLocaleDateString('en-US', {
                          year: 'numeric'
                        }) +
                        '-' +
                        new Date(item.status.delivery_date_start).toLocaleDateString('en-US', {
                          month: '2-digit'
                        }) +
                        '-' +
                        new Date(item.status.delivery_date_start).toLocaleDateString('en-US', {
                          day: '2-digit'
                        })
                      : ''
                  }">
                </td>
                <td>
                  <input type="date" id="deliveryDateTarget" class="form-control" value="${
                    item.status.delivery_date_target
                      ? new Date(item.status.delivery_date_target).toLocaleDateString('en-US', {
                          year: 'numeric'
                        }) +
                        '-' +
                        new Date(item.status.delivery_date_target).toLocaleDateString('en-US', {
                          month: '2-digit'
                        }) +
                        '-' +
                        new Date(item.status.delivery_date_target).toLocaleDateString('en-US', {
                          day: '2-digit'
                        })
                      : ''
                  }">
                </td>
                <td><input type="text" id="deliveryNote" class="form-control" value="${
                  item.status.delivery_note ? item.status.delivery_note : ''
                }"</td>
              </tr>
            </tbody>
          </table>
          <span hidden="true"><input type="checkbox" id="iCheck"> <b>Close this project</b> (remove from working list)</span>`
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.setState({ isLoading: true });

            const data = new FormData();
            data.append('startDate', result.value.startDate);
            data.append('deadlineDate', result.value.deadlineDate);
            data.append('note', result.value.note);
            data.append('purchaseStatus', result.value.purchaseStatus);
            data.append('purchaseDateStart', result.value.purchaseDateStart);
            data.append('purchaseDateTarget', result.value.purchaseDateTarget);
            data.append('purchaseAssignee', result.value.purchaseAssignee);
            data.append('purchaseNote', result.value.purchaseNote);
            data.append('machineStatus', result.value.machineStatus);
            data.append('machineDateStart', result.value.machineDateStart);
            data.append('machineDateTarget', result.value.machineDateTarget);
            data.append('machineAssignee', result.value.machineAssignee);
            data.append('machineNote', result.value.machineNote);
            data.append('assemblyStatus', result.value.assemblyStatus);
            data.append('assemblyDateStart', result.value.assemblyDateStart);
            data.append('assemblyDateTarget', result.value.assemblyDateTarget);
            data.append('assemblyAssignee', result.value.assemblyAssignee);
            data.append('assemblyNote', result.value.assemblyNote);
            data.append('mockupStatus', result.value.mockupStatus);
            data.append('mockupDateStart', result.value.mockupDateStart);
            data.append('mockupDateTarget', result.value.mockupDateTarget);
            data.append('mockupAssignee', result.value.mockupAssignee);
            data.append('mockupNote', result.value.mockupNote);
            data.append('paintStatus', result.value.paintStatus);
            data.append('paintDateStart', result.value.paintDateStart);
            data.append('paintDateTarget', result.value.paintDateTarget);
            data.append('paintAssignee', result.value.paintAssignee);
            data.append('paintNote', result.value.paintNote);
            data.append('shipStatus', result.value.shipStatus);
            data.append('shipDateStart', result.value.shipDateStart);
            data.append('shipDateTarget', result.value.shipDateTarget);
            data.append('shipAssignee', result.value.shipAssignee);
            data.append('shipNote', result.value.shipNote);
            data.append('installStatus', result.value.installStatus);
            data.append('installDateStart', result.value.installDateStart);
            data.append('installDateTarget', result.value.installDateTarget);
            data.append('installAssignee', result.value.installAssignee);
            data.append('installNote', result.value.installNote);
            data.append('deliveryStatus', result.value.deliveryStatus);
            data.append('deliveryDateStart', result.value.deliveryDateStart);
            data.append('deliveryDateTarget', result.value.deliveryDateTarget);
            data.append('deliveryAssignee', result.value.deliveryAssignee);
            data.append('deliveryNote', result.value.deliveryNote);
            data.append('iCheck', result.value.iCheck);

            axios
              .post('/roomcode/status/' + roomCodeId, data)
              .then((success) => {
                this.getRoomStatus();
              })
              .catch((e) => {
                this.setState({ isLoading: false });
              });
          }
        });
    });
  };

  handleOnSave = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const data = new FormData();
    data.append('items', JSON.stringify(this.state.list));

    axios
      .post('/roomcode/status/sorting', data)
      .then(async () => {
        await axios.get('/roomcode/status').then((success) => {
          this.setState({
            list: success.data,
            prevList: success.data
          });
        });
      })
      .finally(() => {
        this.setState({
          isLoading: false,
          isEditing: false
        });
      });
  };

  render() {
    if (this.state.invalid) {
      return <Redirect to="/logout" />;
    }

    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <PageHeader title="Status" breadcrumb="hidden" button="hidden">
            <Button
              variant="primary"
              className="float-sm-right"
              hidden={
                this.state.isEditing ||
                this.state.role === params.ROLE.DRAFT ||
                this.state.role === params.ROLE.DRAFT_MANAGER ||
                this.state.role === params.ROLE.PRODUCTION ||
                this.state.role === params.ROLE.PURCHASE ||
                this.state.role === params.ROLE.PURCHASE_MANAGER
              }
              onClick={() => this.setState({ isEditing: true })}
            >
              Edit Sorting
            </Button>
            <ButtonLoading
              name="Save"
              className="btn btn-success float-sm-right mr-2"
              disabled={this.state.isLoading}
              onClick={this.handleOnSave}
              hidden={
                !this.state.isEditing ||
                this.state.role === params.ROLE.DRAFT ||
                this.state.role === params.ROLE.DRAFT_MANAGER ||
                this.state.role === params.ROLE.PRODUCTION ||
                this.state.role === params.ROLE.PURCHASE ||
                this.state.role === params.ROLE.PURCHASE_MANAGER
              }
            />
            <Button
              variant="default"
              className="float-sm-right mr-2"
              disabled={this.state.isLoading}
              hidden={
                !this.state.isEditing ||
                this.state.role === params.ROLE.DRAFT ||
                this.state.role === params.ROLE.DRAFT_MANAGER ||
                this.state.role === params.ROLE.PRODUCTION ||
                this.state.role === params.ROLE.PURCHASE ||
                this.state.role === params.ROLE.PURCHASE_MANAGER
              }
              onClick={() => this.setState({ isEditing: false, list: this.state.prevList })}
            >
              Cancel
            </Button>
          </PageHeader>

          <section className="content">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <PageLoader marginTop="10%" loading={this.state.isLoading} />
                  <Card>
                    <Card.Body>
                      <Table hover>
                        <thead style={{ textAlign: 'center' }}>
                          <tr>
                            <th style={{ width: '2%' }}>#</th>
                            <th>Project</th>
                            <th style={{ width: '9%' }}>
                              จัดซื้อ
                              <br />
                              <small>Purchasing</small>
                            </th>
                            <th style={{ width: '9%' }}>
                              ผลิต
                              <br />
                              <small>Production</small>
                            </th>
                            <th style={{ width: '9%' }}>
                              ประกอบ
                              <br />
                              <small>Assembly</small>
                            </th>
                            <th style={{ width: '9%' }}>
                              ตั้งตู้
                              <br />
                              <small>MockUp</small>
                            </th>
                            <th style={{ width: '9%' }}>
                              ทำสี
                              <br />
                              <small>Painting</small>
                            </th>
                            <th style={{ width: '9%' }}>
                              ขนส่ง
                              <br />
                              <small>Shipping</small>
                            </th>
                            <th style={{ width: '9%' }}>
                              ติดตั้ง
                              <br />
                              <small>Installation</small>
                            </th>
                            <th style={{ width: '9%' }}>
                              ส่งมอบ
                              <br />
                              <small>Deliver</small>
                            </th>
                          </tr>
                        </thead>
                        <ReactSortable
                          animation={150}
                          onChange={null}
                          list={this.state.list}
                          setList={(newState) => this.setState({ list: newState })}
                          tag="tbody"
                          filter={'.sortable-disabled'}
                        >
                          {this.state.list.map((item) => (
                            <tr
                              key={item.id}
                              style={{ textAlign: 'center' }}
                              className={this.state.isEditing ? 'sortable-enabled' : 'sortable-disabled'}
                            >
                              <td>{item.sequence}</td>
                              <td style={{ textAlign: 'left' }}>
                                <a
                                  target={'_blank'}
                                  rel={'noopener noreferrer'}
                                  hidden={!(item.job && item.job.install_file_path)}
                                  href={item.job && item.job.install_file_path ? item.job.install_file_path : ''}
                                >
                                  {item.project_id + '_' + item.room_code}
                                </a>
                                <span
                                  style={{
                                    display: item.job && item.job.install_file_path ? 'none' : ''
                                  }}
                                >
                                  {item.project_id + '_' + item.room_code}
                                </span>
                                <Button
                                  id={item.id}
                                  className="fas fa-file-alt"
                                  variant="tool"
                                  hidden={!item.job_id}
                                  href={'/report/' + item.job_id}
                                  target={'_blank'}
                                ></Button>{' '}
                                {item.job_id ? '(#' + item.job_id + ')' : ''}
                                <OverlayTrigger overlay={<Popover>{item.status.note}</Popover>}>
                                  <span>
                                    {' '}
                                    <i
                                      style={{
                                        display: item.status.note ? '' : 'none'
                                      }}
                                      className="fas fa-comment-dots"
                                    ></i>
                                  </span>
                                </OverlayTrigger>
                                <br />
                                <div
                                  className={
                                    item.status.deadline_date
                                      ? Math.ceil((new Date(item.status.deadline_date) - new Date()) / (1000 * 60 * 60 * 24)) > 14
                                        ? 'badge badge-info'
                                        : Math.ceil((new Date(item.status.deadline_date) - new Date()) / (1000 * 60 * 60 * 24)) > 7
                                        ? 'badge badge-success'
                                        : Math.ceil((new Date(item.status.deadline_date) - new Date()) / (1000 * 60 * 60 * 24)) > 3
                                        ? 'badge badge-warning'
                                        : 'badge badge-danger'
                                      : 'badge badge-secondary'
                                  }
                                >
                                  {item.status.deadline_date
                                    ? new Date(item.status.deadline_date).toLocaleDateString('en-US', {
                                        day: '2-digit'
                                      }) +
                                      '-' +
                                      new Date(item.status.deadline_date).toLocaleDateString('en-US', {
                                        month: 'short'
                                      }) +
                                      '-' +
                                      new Date(item.status.deadline_date).toLocaleDateString('en-US', {
                                        year: '2-digit'
                                      })
                                    : 'n/a'}
                                  {item.status.deadline_date
                                    ? ' (' +
                                      Math.ceil((new Date(item.status.deadline_date) - new Date()) / (1000 * 60 * 60 * 24)) +
                                      ' Days)'
                                    : ''}
                                </div>
                                <Button
                                  id={item.id}
                                  className="fas fa-edit"
                                  variant="tool"
                                  onClick={this.handleOnUpdate}
                                  hidden={
                                    this.state.isEditing ||
                                    this.state.role === params.ROLE.DRAFT ||
                                    this.state.role === params.ROLE.DRAFT_MANAGER ||
                                    this.state.role === params.ROLE.PRODUCTION ||
                                    this.state.role === params.ROLE.PURCHASE
                                  }
                                ></Button>
                              </td>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>
                                    จัดซื้อ
                                    <br />
                                    <small>Purchasing</small>
                                  </Tooltip>
                                }
                                delay={{ show: 500, hide: 300 }}
                              >
                                <td
                                  className={
                                    item.status.purchase_status === params.JOBSTATUS.NEW
                                      ? 'bg-'
                                      : item.status.purchase_status === params.JOBSTATUS.DOING
                                      ? 'bg-warning'
                                      : item.status.purchase_status === params.JOBSTATUS.WAITING
                                      ? 'bg-danger'
                                      : item.status.purchase_status === params.JOBSTATUS.COMPLETED
                                      ? 'bg-success'
                                      : item.status.purchase_status === params.JOBSTATUS.PLANNED
                                      ? 'bg-info'
                                      : item.status.purchase_status === params.JOBSTATUS.RESERVED
                                      ? 'bg-primary'
                                      : 'bg-secondary'
                                  }
                                >
                                  <small>
                                    {/*display underscore_text*/}
                                    <OverlayTrigger overlay={<Popover>{item.status.purchase_note}</Popover>}>
                                      <u
                                        style={{
                                          display: item.status.purchase_note ? '' : 'none'
                                        }}
                                      >
                                        {item.status.purchase_status ? item.status.purchase_status : 'N/A'}
                                      </u>
                                    </OverlayTrigger>
                                    {/*display non underscore_text*/}
                                    <span
                                      style={{
                                        display: item.status.purchase_note ? 'none' : ''
                                      }}
                                    >
                                      {item.status.purchase_status ? item.status.purchase_status : 'N/A'}
                                    </span>
                                    <br />
                                    {item.status.purchase_date_target
                                      ? new Date(item.status.purchase_date_target).toLocaleDateString('en-US', {
                                          day: '2-digit'
                                        }) +
                                        '-' +
                                        new Date(item.status.purchase_date_target).toLocaleDateString('en-US', {
                                          month: 'short'
                                        }) +
                                        '-' +
                                        new Date(item.status.purchase_date_target).toLocaleDateString('en-US', {
                                          year: '2-digit'
                                        })
                                      : ''}
                                  </small>
                                </td>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>
                                    ผลิต
                                    <br />
                                    <small>Production</small>
                                  </Tooltip>
                                }
                                delay={{ show: 500, hide: 300 }}
                              >
                                <td
                                  className={
                                    item.status.machine_status === params.JOBSTATUS.NEW
                                      ? 'bg-'
                                      : item.status.machine_status === params.JOBSTATUS.DOING
                                      ? 'bg-warning'
                                      : item.status.machine_status === params.JOBSTATUS.WAITING
                                      ? 'bg-danger'
                                      : item.status.machine_status === params.JOBSTATUS.COMPLETED
                                      ? 'bg-success'
                                      : item.status.machine_status === params.JOBSTATUS.PLANNED
                                      ? 'bg-info'
                                      : item.status.machine_status === params.JOBSTATUS.RESERVED
                                      ? 'bg-primary'
                                      : 'bg-secondary'
                                  }
                                >
                                  <small>
                                    {/*display underscore_text*/}
                                    <OverlayTrigger overlay={<Popover>{item.status.machine_note}</Popover>}>
                                      <u
                                        style={{
                                          display: item.status.machine_note ? '' : 'none'
                                        }}
                                      >
                                        {item.status.machine_status ? item.status.machine_status : 'N/A'}
                                      </u>
                                    </OverlayTrigger>
                                    {/*display non underscore_text*/}
                                    <span
                                      style={{
                                        display: item.status.machine_note ? 'none' : ''
                                      }}
                                    >
                                      {item.status.machine_status ? item.status.machine_status : 'N/A'}
                                    </span>
                                    <br />
                                    {item.status.machine_date_target
                                      ? new Date(item.status.machine_date_target).toLocaleDateString('en-US', {
                                          day: '2-digit'
                                        }) +
                                        '-' +
                                        new Date(item.status.machine_date_target).toLocaleDateString('en-US', {
                                          month: 'short'
                                        }) +
                                        '-' +
                                        new Date(item.status.machine_date_target).toLocaleDateString('en-US', {
                                          year: '2-digit'
                                        })
                                      : ''}
                                  </small>
                                </td>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>
                                    ประกอบ
                                    <br />
                                    <small>Assembly</small>
                                  </Tooltip>
                                }
                                delay={{ show: 500, hide: 300 }}
                              >
                                <td
                                  className={
                                    item.status.assembly_status === params.JOBSTATUS.NEW
                                      ? 'bg-'
                                      : item.status.assembly_status === params.JOBSTATUS.DOING
                                      ? 'bg-warning'
                                      : item.status.assembly_status === params.JOBSTATUS.WAITING
                                      ? 'bg-danger'
                                      : item.status.assembly_status === params.JOBSTATUS.COMPLETED
                                      ? 'bg-success'
                                      : item.status.assembly_status === params.JOBSTATUS.PLANNED
                                      ? 'bg-info'
                                      : item.status.assembly_status === params.JOBSTATUS.RESERVED
                                      ? 'bg-primary'
                                      : 'bg-secondary'
                                  }
                                >
                                  <small>
                                    {/*display underscore_text*/}
                                    <OverlayTrigger overlay={<Popover>{item.status.assembly_note}</Popover>}>
                                      <u
                                        style={{
                                          display: item.status.assembly_note ? '' : 'none'
                                        }}
                                      >
                                        {item.status.assembly_status ? item.status.assembly_status : 'N/A'}
                                      </u>
                                    </OverlayTrigger>
                                    {/*display non underscore_text*/}
                                    <span
                                      style={{
                                        display: item.status.assembly_note ? 'none' : ''
                                      }}
                                    >
                                      {item.status.assembly_status ? item.status.assembly_status : 'N/A'}
                                    </span>
                                    <br />
                                    {item.status.assembly_date_target
                                      ? new Date(item.status.assembly_date_target).toLocaleDateString('en-US', {
                                          day: '2-digit'
                                        }) +
                                        '-' +
                                        new Date(item.status.assembly_date_target).toLocaleDateString('en-US', {
                                          month: 'short'
                                        }) +
                                        '-' +
                                        new Date(item.status.assembly_date_target).toLocaleDateString('en-US', {
                                          year: '2-digit'
                                        })
                                      : ''}
                                  </small>
                                </td>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>
                                    ตั้งตู้
                                    <br />
                                    <small>MockUp</small>
                                  </Tooltip>
                                }
                                delay={{ show: 500, hide: 300 }}
                              >
                                <td
                                  className={
                                    item.status.mockup_status === params.JOBSTATUS.NEW
                                      ? 'bg-'
                                      : item.status.mockup_status === params.JOBSTATUS.DOING
                                      ? 'bg-warning'
                                      : item.status.mockup_status === params.JOBSTATUS.WAITING
                                      ? 'bg-danger'
                                      : item.status.mockup_status === params.JOBSTATUS.COMPLETED
                                      ? 'bg-success'
                                      : item.status.mockup_status === params.JOBSTATUS.PLANNED
                                      ? 'bg-info'
                                      : item.status.mockup_status === params.JOBSTATUS.RESERVED
                                      ? 'bg-primary'
                                      : 'bg-secondary'
                                  }
                                >
                                  <small>
                                    {/*display underscore_text*/}
                                    <OverlayTrigger overlay={<Popover>{item.status.mockup_note}</Popover>}>
                                      <u
                                        style={{
                                          display: item.status.mockup_note ? '' : 'none'
                                        }}
                                      >
                                        {item.status.mockup_status ? item.status.mockup_status : 'N/A'}
                                      </u>
                                    </OverlayTrigger>
                                    {/*display non underscore_text*/}
                                    <span
                                      style={{
                                        display: item.status.mockup_note ? 'none' : ''
                                      }}
                                    >
                                      {item.status.mockup_status ? item.status.mockup_status : 'N/A'}
                                    </span>
                                    <br />
                                    {item.status.mockup_date_target
                                      ? new Date(item.status.mockup_date_target).toLocaleDateString('en-US', {
                                          day: '2-digit'
                                        }) +
                                        '-' +
                                        new Date(item.status.mockup_date_target).toLocaleDateString('en-US', {
                                          month: 'short'
                                        }) +
                                        '-' +
                                        new Date(item.status.mockup_date_target).toLocaleDateString('en-US', {
                                          year: '2-digit'
                                        })
                                      : ''}
                                  </small>
                                </td>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>
                                    ทำสี
                                    <br />
                                    <small>Painting</small>
                                  </Tooltip>
                                }
                                delay={{ show: 500, hide: 300 }}
                              >
                                <td
                                  className={
                                    item.status.paint_status === params.JOBSTATUS.NEW
                                      ? 'bg-'
                                      : item.status.paint_status === params.JOBSTATUS.DOING
                                      ? 'bg-warning'
                                      : item.status.paint_status === params.JOBSTATUS.WAITING
                                      ? 'bg-danger'
                                      : item.status.paint_status === params.JOBSTATUS.COMPLETED
                                      ? 'bg-success'
                                      : item.status.paint_status === params.JOBSTATUS.PLANNED
                                      ? 'bg-info'
                                      : item.status.paint_status === params.JOBSTATUS.RESERVED
                                      ? 'bg-primary'
                                      : 'bg-secondary'
                                  }
                                >
                                  <small>
                                    {/*display underscore_text*/}
                                    <OverlayTrigger overlay={<Popover>{item.status.paint_note}</Popover>}>
                                      <u
                                        style={{
                                          display: item.status.paint_note ? '' : 'none'
                                        }}
                                      >
                                        {item.status.paint_status ? item.status.paint_status : 'N/A'}
                                      </u>
                                    </OverlayTrigger>
                                    {/*display non underscore_text*/}
                                    <span
                                      style={{
                                        display: item.status.paint_note ? 'none' : ''
                                      }}
                                    >
                                      {item.status.paint_status ? item.status.paint_status : 'N/A'}
                                    </span>
                                    <br />
                                    {item.status.paint_date_target
                                      ? new Date(item.status.paint_date_target).toLocaleDateString('en-US', {
                                          day: '2-digit'
                                        }) +
                                        '-' +
                                        new Date(item.status.paint_date_target).toLocaleDateString('en-US', {
                                          month: 'short'
                                        }) +
                                        '-' +
                                        new Date(item.status.paint_date_target).toLocaleDateString('en-US', {
                                          year: '2-digit'
                                        })
                                      : ''}
                                  </small>
                                </td>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>
                                    ขนส่ง
                                    <br />
                                    <small>Shipping</small>
                                  </Tooltip>
                                }
                                delay={{ show: 500, hide: 300 }}
                              >
                                <td
                                  className={
                                    item.status.ship_status === params.JOBSTATUS.NEW
                                      ? 'bg-'
                                      : item.status.ship_status === params.JOBSTATUS.DOING
                                      ? 'bg-warning'
                                      : item.status.ship_status === params.JOBSTATUS.WAITING
                                      ? 'bg-danger'
                                      : item.status.ship_status === params.JOBSTATUS.COMPLETED
                                      ? 'bg-success'
                                      : item.status.ship_status === params.JOBSTATUS.PLANNED
                                      ? 'bg-info'
                                      : item.status.ship_status === params.JOBSTATUS.RESERVED
                                      ? 'bg-primary'
                                      : 'bg-secondary'
                                  }
                                >
                                  <small>
                                    {/*display underscore_text*/}
                                    <OverlayTrigger overlay={<Popover>{item.status.ship_note}</Popover>}>
                                      <u
                                        style={{
                                          display: item.status.ship_note ? '' : 'none'
                                        }}
                                      >
                                        {item.status.ship_status ? item.status.ship_status : 'N/A'}
                                      </u>
                                    </OverlayTrigger>
                                    {/*display non underscore_text*/}
                                    <span
                                      style={{
                                        display: item.status.ship_note ? 'none' : ''
                                      }}
                                    >
                                      {item.status.ship_status ? item.status.ship_status : 'N/A'}
                                    </span>
                                    <br />
                                    {item.status.ship_date_target
                                      ? new Date(item.status.ship_date_target).toLocaleDateString('en-US', {
                                          day: '2-digit'
                                        }) +
                                        '-' +
                                        new Date(item.status.ship_date_target).toLocaleDateString('en-US', {
                                          month: 'short'
                                        }) +
                                        '-' +
                                        new Date(item.status.ship_date_target).toLocaleDateString('en-US', {
                                          year: '2-digit'
                                        })
                                      : ''}
                                  </small>
                                </td>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>
                                    ติดตั้ง
                                    <br />
                                    <small>Installation</small>
                                  </Tooltip>
                                }
                                delay={{ show: 500, hide: 300 }}
                              >
                                <td
                                  className={
                                    item.status.install_status === params.JOBSTATUS.NEW
                                      ? 'bg-'
                                      : item.status.install_status === params.JOBSTATUS.DOING
                                      ? 'bg-warning'
                                      : item.status.install_status === params.JOBSTATUS.WAITING
                                      ? 'bg-danger'
                                      : item.status.install_status === params.JOBSTATUS.COMPLETED
                                      ? 'bg-success'
                                      : item.status.install_status === params.JOBSTATUS.PLANNED
                                      ? 'bg-info'
                                      : item.status.install_status === params.JOBSTATUS.RESERVED
                                      ? 'bg-primary'
                                      : 'bg-secondary'
                                  }
                                >
                                  <small>
                                    {/*display underscore_text*/}
                                    <OverlayTrigger overlay={<Popover>{item.status.install_note}</Popover>}>
                                      <u
                                        style={{
                                          display: item.status.install_note ? '' : 'none'
                                        }}
                                      >
                                        {item.status.install_status ? item.status.install_status : 'N/A'}
                                      </u>
                                    </OverlayTrigger>
                                    {/*display non underscore_text*/}
                                    <span
                                      style={{
                                        display: item.status.install_note ? 'none' : ''
                                      }}
                                    >
                                      {item.status.install_status ? item.status.install_status : 'N/A'}
                                    </span>
                                    <br />
                                    {item.status.install_date_target
                                      ? new Date(item.status.install_date_target).toLocaleDateString('en-US', {
                                          day: '2-digit'
                                        }) +
                                        '-' +
                                        new Date(item.status.install_date_target).toLocaleDateString('en-US', {
                                          month: 'short'
                                        }) +
                                        '-' +
                                        new Date(item.status.install_date_target).toLocaleDateString('en-US', {
                                          year: '2-digit'
                                        })
                                      : ''}
                                  </small>
                                </td>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>
                                    ส่งมอบ
                                    <br />
                                    <small>Deliver</small>
                                  </Tooltip>
                                }
                                delay={{ show: 500, hide: 300 }}
                              >
                                <td
                                  className={
                                    item.status.delivery_status === params.JOBSTATUS.NEW
                                      ? 'bg-'
                                      : item.status.delivery_status === params.JOBSTATUS.DOING
                                      ? 'bg-warning'
                                      : item.status.delivery_status === params.JOBSTATUS.WAITING
                                      ? 'bg-danger'
                                      : item.status.delivery_status === params.JOBSTATUS.COMPLETED
                                      ? 'bg-success'
                                      : item.status.delivery_status === params.JOBSTATUS.PLANNED
                                      ? 'bg-info'
                                      : item.status.delivery_status === params.JOBSTATUS.RESERVED
                                      ? 'bg-primary'
                                      : 'bg-secondary'
                                  }
                                >
                                  <small>
                                    {/*display underscore_text*/}
                                    <OverlayTrigger overlay={<Popover>{item.status.delivery_note}</Popover>}>
                                      <u
                                        style={{
                                          display: item.status.delivery_note ? '' : 'none'
                                        }}
                                      >
                                        {item.status.delivery_status ? item.status.delivery_status : 'N/A'}
                                      </u>
                                    </OverlayTrigger>
                                    {/*display non underscore_text*/}
                                    <span
                                      style={{
                                        display: item.status.delivery_note ? 'none' : ''
                                      }}
                                    >
                                      {item.status.delivery_status ? item.status.delivery_status : 'N/A'}
                                    </span>
                                    <br />
                                    {item.status.delivery_date_target
                                      ? new Date(item.status.delivery_date_target).toLocaleDateString('en-US', {
                                          day: '2-digit'
                                        }) +
                                        '-' +
                                        new Date(item.status.delivery_date_target).toLocaleDateString('en-US', {
                                          month: 'short'
                                        }) +
                                        '-' +
                                        new Date(item.status.delivery_date_target).toLocaleDateString('en-US', {
                                          year: '2-digit'
                                        })
                                      : ''}
                                  </small>
                                </td>
                              </OverlayTrigger>
                            </tr>
                          ))}
                        </ReactSortable>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <Footer />
      </div>
    );
  }
}
