import React, { Component } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Form, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import DataTable from 'react-data-table-component';

import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';
import PageLoader from '../components/PageLoader';

import params from '../utils/params';
import localStorage from '../utils/localStorage';

export default class Item extends Component {
  COLUMNS = [
    {
      name: 'ID',
      width: '15%',
      selector: 'id',
      sortable: true,
      wrap: true,
      cell: (row) => (
        <NavLink exact to={'/item/' + row.id}>
          {row.id}
        </NavLink>
      )
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      wrap: true
    },
    {
      name: 'Remain Qty',
      width: '10%',
      selector: 'remain_qty',
      sortable: true,
      wrap: true
    },
    {
      name: 'Unit',
      width: '10%',
      selector: 'unit',
      sortable: true,
      wrap: true
    },
    {
      name: 'Selling Price',
      width: '10%',
      selector: 'price',
      sortable: true,
      wrap: true,
      cell: (row) =>
        row.price.toLocaleString('en-GB', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
    },
    {
      name: 'Supplier Price',
      width: '10%',
      selector: 'supplier_price',
      sortable: true,
      wrap: true,
      cell: (row) =>
        row.supplier_price.toLocaleString('en-GB', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
    }
  ];

  constructor() {
    super();

    let user = JSON.parse(localStorage.getUser());
    if (!user) {
      this.state = { invalid: true };
      return;
    }

    this.state = {
      isLoading: true,
      role: user.role,

      // table
      items: [],
      filterText: '',
      filterItems: [],
      columns: [],
      selectedRow: [],
      toggledClearRows: false,
      customStyles: {
        headCells: {
          style: {
            fontSize: 16,
            fontWeight: 'bold',
            padding: '5px'
          }
        },
        cells: {
          style: {
            fontSize: 16,
            padding: '5px'
          }
        }
      }
    };
  }

  componentDidMount() {
    axios
      .get('/stockitem')
      .then((success) => {
        this.setState({
          items: success.data,
          columns: this.COLUMNS,
          toggledClearRows: !this.state.toggledClearRows
        });
      })
      .finally(() => {
        this.filterTable(this.state.filterText);
        this.setState({ isLoading: false });
      });
  }

  handleOnSearch = (e) => {
    this.setState({ filterText: e.target.value });
    this.filterTable(e.target.value);
  };

  filterTable = (search) => {
    let filterItems = [];
    let text = search.toString();

    filterItems = this.state.items.filter(
      (item) =>
        (item.id && item.id.toString().toLowerCase().includes(text.toLowerCase())) ||
        (item.name && item.name.toString().toLowerCase().includes(text.toLowerCase())) ||
        (item.remain_qty && item.remain_qty.toString().toLowerCase().includes(text.toLowerCase())) ||
        (item.unit && item.unit.toString().toLowerCase().includes(text.toLowerCase())) ||
        (item.average_cost && item.average_cost.toString().toLowerCase().includes(text.toLowerCase())) ||
        (item.price && item.price.toString().toLowerCase().includes(text.toLowerCase())) ||
        (item.supplier_price && item.supplier_price.toString().toLowerCase().includes(text.toLowerCase()))
    );

    this.setState({ filterItems: filterItems });
  };

  render() {
    if (this.state.invalid) {
      return <Redirect to="/logout" />;
    }

    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <PageHeader
            title="Item"
            breadcrumb="hidden"
            buttonUrl="/item/create"
            button={
              this.state.role === params.ROLE.ACCOUNTING ||
              this.state.role === params.ROLE.DRAFT ||
              this.state.role === params.ROLE.DRAFT_MANAGER ||
              this.state.role === params.ROLE.PURCHASE
                ? 'hidden'
                : ''
            }
          />

          <section className="content">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <PageLoader loading={this.state.isLoading} />
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col md={12}>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                name="searchBox"
                                className="float-right"
                                placeholder="Search ..."
                                value={this.state.filterText}
                                onChange={this.handleOnSearch}
                              />
                              <InputGroup.Append>
                                <Form.Label>
                                  <Button
                                    variant="default"
                                    onClick={() => {
                                      this.setState({ filterText: '' });
                                      this.filterTable('');
                                    }}
                                  >
                                    <i className="fas fa-times"></i>
                                  </Button>
                                </Form.Label>
                              </InputGroup.Append>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <DataTable
                        columns={this.state.columns}
                        data={this.state.filterItems}
                        customStyles={this.state.customStyles}
                        highlightOnHover
                        clearSelectedRows={this.state.toggledClearRows}
                        noHeader={true}
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[50, 100, 500, 1000]}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <Footer />
      </div>
    );
  }
}
