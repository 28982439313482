import React, { Component } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Form, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import DataTable from 'react-data-table-component';

import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';
import PageLoader from '../components/PageLoader';

import params from '../utils/params';
import localStorage from '../utils/localStorage';

export default class Job extends Component {
  COLUMNS = [
    {
      name: 'Room Code ID',
      selector: 'room_code_id',
      wrap: true,
      sortable: true,
      omit: true
    },
    {
      name: 'Project',
      width: '15%',
      selector: 'project_id',
      wrap: true,
      sortable: true,
      cell: (row) => (
        <NavLink exact to={'/job/' + row.file_id}>
          {row.project_id}
        </NavLink>
      )
    },
    {
      name: 'Room Code',
      selector: 'room_code',
      wrap: true,
      sortable: true,
      cell: (row) => (
        <NavLink exact to={'/job/' + row.file_id}>
          {row.room_code}
        </NavLink>
      )
    },
    {
      name: 'Job ID',
      width: '10%',
      selector: 'job_id',
      sortable: true,
      wrap: true
    },
    {
      name: 'Status',
      width: '15%',
      selector: 'status',
      sortable: true,
      wrap: true,
      cell: (row) => (
        <small
          className={
            row.file.step === 'PYTHA'
              ? row.file.file_pytha_status === params.STATUS.ERROR
                ? 'badge badge-danger'
                : row.file.file_pytha_status === params.STATUS.SUCCESS
                ? 'badge badge-success'
                : row.file.file_pytha_status === params.STATUS.WARNING
                ? 'badge badge-warning'
                : 'badge badge-secondary'
              : row.file.step === 'OPTICUT'
              ? row.file.file_export_status === params.STATUS.ERROR
                ? 'badge badge-danger'
                : row.file.file_export_status === params.STATUS.SUCCESS
                ? 'badge badge-success'
                : row.file.file_export_status === params.STATUS.WARNING
                ? 'badge badge-warning'
                : 'badge badge-secondary'
              : row.file.step === 'JOB'
              ? row.job.status === params.STATUS.CANCELLED
                ? 'badge badge-danger'
                : row.job.status === params.STATUS.PENDING
                ? 'badge badge-warning'
                : row.job.status === params.STATUS.DRAFT
                ? 'badge badge-secondary'
                : row.job.status === params.STATUS.SUBMITTED
                ? 'badge badge-primary'
                : row.job.status === params.STATUS.CONFIRMED
                ? 'badge badge-success'
                : 'badge'
              : 'badge'
          }
        >
          {row.file.step === 'PYTHA'
            ? row.file.file_pytha_status
            : row.file.step === 'OPTICUT'
            ? row.file.file_export_status
            : row.job.status}
        </small>
      )
    },
    {
      name: 'Updated By',
      width: '15%',
      selector: 'updated_by',
      sortable: true,
      wrap: true,
      cell: (row) => <span>{row.firstname + ' (' + row.nickname + ')'}</span>
    },
    {
      name: 'Updated At',
      width: '20%',
      selector: 'updated_at',
      sortable: true,
      wrap: true,
      cell: (row) => <span>{new Date(row.file.updated_at).toLocaleString('en-GB')}</span>
    }
  ];

  constructor() {
    super();

    let user = JSON.parse(localStorage.getUser());
    if (!user) {
      this.state = { invalid: true };
      return;
    }

    this.state = {
      isLoading: true,
      role: user.role,

      // table
      items: [],
      filterText: '',
      filterItems: [],
      columns: [],
      selectedRow: [],
      toggledClearRows: false,
      customStyles: {
        headCells: {
          style: {
            fontSize: 16,
            fontWeight: 'bold',
            padding: '5px'
          }
        },
        cells: {
          style: {
            fontSize: 16,
            padding: '5px'
          }
        }
      }
    };
  }

  componentDidMount() {
    axios
      .get('/roomcode/file')
      .then((success) => {
        this.setState({
          items: success.data,
          columns: this.COLUMNS,
          toggledClearRows: !this.state.toggledClearRows
        });
      })
      .finally(() => {
        this.filterTable(this.state.filterText);
        this.setState({ isLoading: false });
      });
  }

  handleOnSearch = (e) => {
    this.setState({ filterText: e.target.value });
    this.filterTable(e.target.value);
  };

  filterTable = (search) => {
    let filterItems = [];
    let text = search.toString();

    filterItems = this.state.items.filter(
      (item) =>
        (item.project_id && item.project_id.toString().toLowerCase().includes(text.toLowerCase())) ||
        (item.room_code && item.room_code.toString().toLowerCase().includes(text.toLowerCase())) ||
        (item.job_id && item.job_id.toString().toLowerCase().includes(text.toLowerCase())) ||
        (item.firstname + ' (' + item.nickname + ')' &&
          (item.firstname + ' (' + item.nickname + ')').toString().toLowerCase().includes(text.toLowerCase())) ||
        (new Date(item.file.updated_at).toLocaleString('en-GB') &&
          new Date(item.file.updated_at).toLocaleString('en-GB').toString().toLowerCase().includes(text.toLowerCase()))
    );

    this.setState({ filterItems: filterItems });
  };

  render() {
    if (this.state.invalid) {
      return <Redirect to="/logout" />;
    }

    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <PageHeader
            title="Job"
            breadcrumb="hidden"
            buttonUrl="/job/create"
            button={this.state.role === params.ROLE.PRODUCTION_MANAGER ? 'hidden' : ''}
          />

          <section className="content">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <PageLoader loading={this.state.isLoading} />
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col md={12}>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                name="searchBox"
                                className="float-right"
                                placeholder="Search ..."
                                value={this.state.filterText}
                                onChange={this.handleOnSearch}
                              />
                              <InputGroup.Append>
                                <Form.Label>
                                  <Button
                                    variant="default"
                                    onClick={() => {
                                      this.setState({ filterText: '' });
                                      this.filterTable('');
                                    }}
                                  >
                                    <i className="fas fa-times"></i>
                                  </Button>
                                </Form.Label>
                              </InputGroup.Append>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <DataTable
                        keyField="room_code_id"
                        columns={this.state.columns}
                        data={this.state.filterItems}
                        customStyles={this.state.customStyles}
                        highlightOnHover
                        clearSelectedRows={this.state.toggledClearRows}
                        noHeader={true}
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[50, 100, 500, 1000]}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <Footer />
      </div>
    );
  }
}
